import React from 'react';

export const TeusLogo = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0.5455930233001709 174 47.45560836791992"
         fill="none"
         preserveAspectRatio="xMidYMid meet" role="img">
        <path
            d="M55.3628 0.545593H15.8185H8.0682H0V16.3641H15.8185V8.45486H31.6371H39.5463V16.3641L55.3628 0.545593Z"
            fill="white"></path>
        <path d="M15.8184 48.0012L31.6369 32.1827V8.45486L15.8184 24.2734V48.0012Z" fill="white"></path>
        <path
            d="M95.3003 32.2308H78.8209C76.4535 32.2308 74.5442 30.3047 74.5442 27.9185V20.6743C74.5442 18.2881 76.4535 16.362 78.8209 16.362H91.0236C93.3909 16.362 95.3003 18.2881 95.3003 20.6743V25.6766H77.2796V29.4702H95.3003V32.2287V32.2308ZM92.5628 22.9182V19.1246H77.2796V22.9182H92.5628Z"
            fill="white"></path>
        <path
            d="M118.777 32.2307H102.298C99.9306 32.2307 98.0212 30.3047 98.0212 27.9185V16.3641H100.759V29.4702H116.042V16.3641H118.779V32.2307H118.777Z"
            fill="white"></path>
        <path
            d="M142.354 27.9185C142.354 30.3047 140.445 32.2307 138.077 32.2307H121.596V29.4723H139.617V25.6787H125.132C122.765 25.6787 120.856 23.7526 120.856 21.3665V20.6763C120.856 18.2902 122.765 16.3641 125.132 16.3641H142.363V19.1225H123.591V22.9161H138.075C140.443 22.9161 142.352 24.8422 142.352 27.2284V27.9185H142.354Z"
            fill="white"></path>
        <path
            d="M155.885 19.1769V22.1005H155.153V21.1427C154.731 21.7429 154.032 22.1821 153.106 22.1821C151.481 22.1821 150.239 20.9064 150.239 19.2585C150.239 17.6105 151.506 16.3348 153.154 16.3348C154.291 16.3348 155.283 17.002 155.68 18.0413H154.754C154.429 17.4809 153.869 17.1149 153.154 17.1149C151.985 17.1149 151.09 18.0497 151.09 19.2585C151.09 20.4672 151.966 21.402 153.112 21.402C154.168 21.402 154.841 20.7119 155.044 19.9151H153.022V19.1769H155.881H155.885Z"
            fill="white"></path>
        <path
            d="M159.54 17.9828V18.7377C159.394 18.7064 159.248 18.7064 159.078 18.7064C158.34 18.7064 157.876 19.2585 157.876 19.9005V22.1026H157.104V18.0413H157.836V18.6666C158.129 18.2442 158.624 17.9598 159.176 17.9598C159.298 17.9598 159.427 17.9681 159.542 17.9849L159.54 17.9828Z"
            fill="white"></path>
        <path
            d="M159.744 20.0699C159.744 18.8758 160.687 17.9577 161.904 17.9577C163.121 17.9577 164.048 18.8758 164.048 20.0699C164.048 21.264 163.113 22.1821 161.904 22.1821C160.695 22.1821 159.744 21.264 159.744 20.0699ZM163.253 20.0699C163.253 19.2815 162.667 18.6729 161.904 18.6729C161.141 18.6729 160.538 19.2815 160.538 20.0699C160.538 20.8583 161.132 21.4669 161.904 21.4669C162.676 21.4669 163.253 20.8583 163.253 20.0699Z"
            fill="white"></path>
        <path
            d="M168.638 18.0392V22.1005H167.906V21.4501C167.613 21.8893 167.117 22.1821 166.565 22.1821C165.599 22.1821 164.819 21.5735 164.819 20.2895V18.0392H165.591V20.2727C165.591 21.011 166.086 21.4418 166.703 21.4418C167.419 21.4418 167.864 20.8729 167.864 20.2079V18.0392H168.636H168.638Z"
            fill="white"></path>
        <path
            d="M173.998 20.0699C173.998 21.3038 173.161 22.1821 172.08 22.1821C171.536 22.1821 171.041 21.9228 170.708 21.492V23.4829H169.937V18.0413H170.668V18.7001C171.009 18.2442 171.513 17.9598 172.082 17.9598C173.163 17.9598 174 18.8381 174 20.072L173.998 20.0699ZM173.201 20.0699C173.201 19.2585 172.674 18.6729 171.95 18.6729C171.227 18.6729 170.683 19.2585 170.683 20.0699C170.683 20.8813 171.218 21.4669 171.95 21.4669C172.682 21.4669 173.201 20.8813 173.201 20.0699Z"
            fill="white"></path>
        <path
            d="M55.3628 19.1246H62.1302H64.1839V26.831V32.2307H66.9193V24.0747V19.1246H71.8296L74.5671 16.3641H55.3628V19.1246Z"
            fill="white"></path>
    </svg>
);

export const TelegramIcon = () => (
    <svg className="svg-icon"
         viewBox="0 0 1024 820" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M417.28 795.733333l11.946667-180.48 327.68-295.253333c14.506667-13.226667-2.986667-19.626667-22.186667-8.106667L330.24 567.466667 155.306667 512c-37.546667-10.666667-37.973333-36.693333 8.533333-55.466667l681.386667-262.826666c31.146667-14.08 61.013333 7.68 49.066666 55.466666l-116.053333 546.56c-8.106667 38.826667-31.573333 48.213333-64 30.293334L537.6 695.466667l-84.906667 82.346666c-9.813333 9.813333-17.92 17.92-35.413333 17.92z"
            fill="currentColor"/>
    </svg>
);

export  const WhatsAppIcon = () => (
    <svg fill="#000000" version="1.1" id="Layer_1"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 -40 308 350">
            <g id="XMLID_468_">
                    <path id="XMLID_469_" d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156
		c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687
		c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887
		c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153
		c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348
		c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802
		c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922
		c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0
		c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458
		C233.168,179.508,230.845,178.393,227.904,176.981z"
                          fill="currentColor"/>
                    <path id="XMLID_470_" d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716
		c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396
		c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z
		 M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188
		l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677
		c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867
		C276.546,215.678,222.799,268.994,156.734,268.994z"
                          fill="currentColor"/>
            </g>
    </svg>
)

export const YoutubeIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 2.7 18 12.6" fill="none" role="img">
            <g clipPath="url(#youtube-clip)">
                    <path
                        d="M7.19 11.33V6.28c1.79.84 3.18 1.65 4.82 2.53-1.35.75-3.03 1.59-4.82 2.52zM17.18 3.76c-.31-.41-.83-.73-1.39-.83C14.13 2.62 3.84 2.62 2.2 2.93c-.45.08-.85.29-1.19.61C-.45 4.89.00 12.11.35 13.28c.15.51.34.88.57 1.13.31.32.73.54 1.18.63 1.36.28 8.36.44 13.61.05.48-.08.91-.33 1.24-.72 1.34-1.34 1.24-9.16.18-10.95z"
                        fill="currentColor"></path>
            </g>
            <defs>
                    <clipPath id="youtube-clip">
                            <rect width="18" height="18" fill="white"></rect>
                    </clipPath>
            </defs>
    </svg>
)

export const FacebookIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="3.5 0 7 14" fill="none" role="img">
            <path
                d="M8.27 14V7.7h1.91L10.5 4.9H8.27V3.54C8.27 2.82 8.29 2.1 9.3 2.1h1.02V.1c0-.03-.88-.1-1.76-.1C6.7 0 5.55 1.16 5.55 3.29V4.9H3.5V7.7h2.05V14h2.72z"
                fill="currentColor"></path>
    </svg>
)

export const InstagramIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" fill="none" role="img">
            <g clipPath="url(#instagram-clip)">
                    <path
                        d="M10.14 0H3.86C1.73 0 0 1.73 0 3.86v6.27C0 12.27 1.73 14 3.86 14h6.27C12.27 14 14 12.27 14 10.14V3.86C14 1.73 12.27 0 10.14 0zM12.76 10.14c0 1.45-1.18 2.62-2.62 2.62H3.86C2.42 12.76 1.24 11.58 1.24 10.14V3.86C1.24 2.42 2.42 1.24 3.86 1.24h6.27C11.58 1.24 12.76 2.42 12.76 3.86v6.27zM7 3.39C5.01 3.39 3.39 5.01 3.39 7c0 1.99 1.62 3.61 3.61 3.61s3.61-1.62 3.61-3.61S8.99 3.39 7 3.39zM7 9.37C5.7 9.37 4.63 8.3 4.63 7S5.7 4.63 7 4.63s2.37 1.07 2.37 2.37-1.07 2.37-2.37 2.37zM10.76 2.34c-.24 0-.47.1-.65.28-.17.17-.27.41-.27.65 0 .24.1.47.27.65.18.17.42.28.65.28.24 0 .47-.1.65-.28.17-.17.28-.41.28-.65 0-.24-.1-.47-.28-.65-.18-.18-.42-.28-.65-.28z"
                        fill="currentColor"></path>
            </g>
            <defs>
                    <clipPath id="instagram-clip">
                            <rect width="14" height="14" fill="white"></rect>
                    </clipPath>
            </defs>
    </svg>
)

export const LinkedinIcon = ()=> (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" fill="none" role="img">
            <g clipPath="url(#linkedin-clip)">
                    <path
                        d="M1.67 15h11.67C14.25 15 15 14.25 15 13.33V1.67C15 .75 14.25 0 13.33 0H1.67C.75 0 0 .75 0 1.67v11.67C0 14.25.75 15 1.67 15z"
                        fill="currentColor"></path>
                    <path
                        d="M12.92 12.92h-2.23V9.13c0-1.04-.4-1.63-1.22-1.63-.89 0-1.36.6-1.36 1.62v3.8H5.97V5.69h2.15v.97c.15-.22.76-1.19 2.29-1.19 1.53 0 2.63.94 2.63 2.87v3.75zm-9.51-8.17c-.73 0-1.32-.6-1.32-1.33 0-.73.6-1.33 1.32-1.33.74 0 1.33.6 1.33 1.33 0 .73-.59 1.33-1.33 1.33zM2.3 12.92h2.24V5.69H2.3v7.23z"
                        fill="black"></path>
            </g>
            <defs>
                    <clipPath id="linkedin-clip">
                            <rect width="15" height="15" fill="white"></rect>
                    </clipPath>
            </defs>
    </svg>
)